import React from 'react';
import styles from './styles.module.less';
import { brandLogoUuid } from '../../lib/brand';
import { ApplyLink, LogInLink } from '../ApplyNowLogIn';

const LoBug = ({
   show = true,
   onClick,
   name,
   jobTitle,
   licenseName,
   licenseId,
   photo,
   applyNow,
   applyNowScript,
   logIn,
   slug,
   applyOffset
}) => {
   let shouldShow = !!show && !!name && !!photo && !!slug;

   if (!shouldShow) return null;

   return (
      <div className={`${styles.LoBug} ${applyOffset ? styles.LoBugOffset : ''}`}>
         <div className={styles.container}>
            <button onClick={onClick}>X</button>
            {(photo.includes(brandLogoUuid) && (
               <div className={styles.logoContainer}>
                  <div className={styles.logo}>
                     <a href={slug}>
                        <img alt="" data-blink-src={photo} />
                     </a>
                  </div>
               </div>
            )) || (
               <div className={styles.picture}>
                  <a href={slug}>
                     <img alt="" data-blink-src={photo} />
                  </a>
               </div>
            )}
            <div className={styles.description}>
               <div className={styles.half}>
                  <a href={slug}>
                     <h1>{name}</h1>
                  </a>
                  <h2>
                     {licenseName} {licenseId}
                  </h2>
                  <h3>{jobTitle}</h3>
               </div>
               <div className={styles.half}>
                  <div className={styles.button}>
                     <ApplyLink applyNow={applyNow} applyNowScript={applyNowScript} slug={slug} />
                  </div>
                  <div className={styles.button}>
                     <LogInLink logIn={logIn} slug={slug} />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default LoBug;
